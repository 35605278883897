import { Component, OnInit, HostListener } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { SnackBarService } from 'src/app/shared/snackbar.service';
import { SnackBarTypes } from 'src/app/shared/enums/snackbar.enums';
import { KEY_CODE } from 'src/app/shared/enums/key-codes.enum';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss', '../common/auth-styles.scss']
})
export class RegisterComponent implements OnInit {
  registerForm = this.fb.group({
    username: ['', Validators.required],
    email: ['', [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[\\w@]{2,}\\s*$')]],
    password: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private snackBarService: SnackBarService
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    if (this.registerForm.valid) {
      const { username, email, password } = this.registerForm.value;
      this.authService.register(username.trim(), email.trim(), password).pipe(take(1)).subscribe(res => {
        this.snackBarService.openSnackBar('User successfully registered. Log in to application.', SnackBarTypes.SUCCESS)
        this.router.navigate(['/login']);
      },
        error => {
          if (error.status === 400) {
            this.snackBarService.openSnackBar('User already exists', SnackBarTypes.ERROR)
          }
          if (error.status === 401) {
            this.snackBarService.openSnackBar('Invalid signup credentials', SnackBarTypes.ERROR)
          }
        })
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ENTER) {
      this.onSubmit();
    }
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }

  getErrorMessage(control: string) {
    if (this.registerForm.controls[control].hasError('required')) {
      return `Enter ${control}`;
    }
    if (control === 'email') {
      return this.registerForm.controls.email.hasError('pattern') ? 'Please enter valid email' : '';
    }
    return '';
  }

}
