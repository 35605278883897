import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { MainComponent } from './home/main/main.component';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule, MatNativeDateModule } from '@angular/material';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { AuthService } from './auth/auth.service';
import { WeightsService } from './services/weights.service';

import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TokenInterceptor } from './auth/interceptors/token.interceptor';
import { NotAuthorizedInterceptor } from './auth/interceptors/not-authorized.interceptor';
import { LoginSuccessOauthComponent } from './auth/login-success-oauth/login-success-oauth.component';
import { CookieService, CookieOptions } from 'angular2-cookie/core';
import { SnackBarService } from './shared/snackbar.service';
import { AddWeightComponent } from './home/add-weight/add-weight.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { WeightsChartComponent } from './home/weights-chart/weights-chart.component';
import { WeightsInfoComponent } from './home/weights-info/weights-info.component';
import { JwtGuardService } from './auth/jwt-guard.service';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSelectModule } from '@angular/material/select';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    AuthComponent,
    LoginComponent,
    RegisterComponent,
    LoginSuccessOauthComponent,
    AddWeightComponent,
    WeightsChartComponent,
    WeightsInfoComponent,
    ResetPasswordComponent,
  ],
  imports: [
    LayoutModule,
    MatIconModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatSelectModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule,
  ],
  
  providers: [
    CookieService,
    { 
      provide: CookieOptions,
      useValue: {}
    },
    JwtGuardService,
    AuthService,
    WeightsService,
    SnackBarService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotAuthorizedInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
