import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { SnackBarService } from 'src/app/shared/snackbar.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss', '../common/auth-styles.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm = this.fb.group({
    email: ['', [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[\\w@]{2,}\\s*$')]],
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private snackBarService: SnackBarService
  ) { }

  ngOnInit() {
  }

  getErrorMessage(control: string) {
    if (this.resetPasswordForm.controls[control].hasError('required')) {
      return `Enter ${control}`;
    }
    if (control === 'email') {
      return this.resetPasswordForm.controls.email.hasError('pattern') ? 'Please enter valid email' : '';
    }
    return '';
  }

  onSubmit() {
    if (this.resetPasswordForm.valid) {
      console.log('request to reset pass')
    }
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }
}
