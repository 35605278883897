import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { WeightsService } from 'src/app/services/weights.service';
import { Weight } from 'src/app/interfaces/weights.interface';
import { setDateToMidnight } from 'src/app/shared/utils';
import { KEY_CODE } from 'src/app/shared/enums/key-codes.enum';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-add-weight',
  templateUrl: './add-weight.component.html',
  styleUrls: ['./add-weight.component.scss']
})
export class AddWeightComponent implements OnInit {

  @Output() addWeight: EventEmitter<Weight> = new EventEmitter();

  weightForm = this.fb.group({
    weight: ['', Validators.required],
  });

  dateSelected;
  dateMax;

  constructor(private fb: FormBuilder, private weightsService: WeightsService, private breakpointObserver: BreakpointObserver) { }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 800px)');
  }

  ngOnInit() {
    this.dateMax = new Date();
    this.dateSelected = new Date();
  }

  onDateChange(event) {
    this.dateSelected = event.value
  }

  onSubmit() {
    const d = setDateToMidnight(this.dateSelected);

    this.addWeight.next({
      value: this.weightForm.value.weight,
      date: setDateToMidnight(this.dateSelected)
    })

  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ENTER) {
      this.onSubmit();
    }
  }

  getErrorMessage() {
    if (this.weightForm.controls['weight'].hasError('required')) {
      return `Please enter weight`;
    }
    return '';
  }

  getSelectedDate() {
    return this.dateSelected ? this.dateSelected.toLocaleDateString() : '';
  }

}
