import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { MainComponent } from './home/main/main.component';
import { LoginSuccessOauthComponent } from './auth/login-success-oauth/login-success-oauth.component';
import { JwtGuardService } from './auth/jwt-guard.service';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login/success', component: LoginSuccessOauthComponent },
  { path: 'password-reset', component: ResetPasswordComponent },
  { path: 'register', component: RegisterComponent },
  { path: '', component: MainComponent, canActivate: [JwtGuardService]  },
  { path: '**', redirectTo: '', canActivate: [JwtGuardService] },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
