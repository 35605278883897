import { Injectable } from '@angular/core';
import { map, tap, take, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, BehaviorSubject, Observable } from 'rxjs';
import { getApiUrl } from '../shared/utils';
import { JwtHelperService } from "@auth0/angular-jwt";


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  jwtHelperService = new JwtHelperService();

  private userLoggedInSubject: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) {
    this.userLoggedInSubject = new BehaviorSubject<boolean>(this.getToken() !== null);
  }

  login(email: string, password: string) {
    return this.http.post<any>(getApiUrl('/auth/login'), { email, password }).pipe(
      map(res => {
        this.saveAuthCredentials(res.accessToken)
        this.userLoggedInSubject.next(true);
        return res;
      }),
      catchError(this.handleError)
    );
    // return this.http.post<any>(this.getUrl('/auth/login'), { username, password }).pipe(
    //   map(user => {
    //     // login successful if there's a jwt token in the response
    //     if (user && user.access_token) {
    //       // store user details and jwt token in local storage to keep user logged in between page refreshes
    //       localStorage.setItem('currentUser', JSON.stringify(user));
    //       // this.currentUserSubject.next(user);
    //     }
    //     return user;
    //   })
    // );
  }

  OAuthUserLogin(access_token: string) {
    this.saveAuthCredentials(access_token);
    this.userLoggedInSubject.next(true);
  }

  register(username: string, email: string, password: string) {
    return this.http.post<any>(getApiUrl('/auth/register'), { username, email, password }).pipe(
      catchError(this.handleError)
    );
  }

  logout() {
    this.removeToken();
    this.userLoggedInSubject.next(null);
  }

  saveAuthCredentials(accessToken: string) {
    localStorage.setItem('user_access_token', accessToken);
  }

  isLoggedIn() {
    return this.userLoggedInSubject;
  }

  isAuthenticated() {
    const token = this.getToken();
    // Check whether the token is expired and return
    // true or false
    if (token) {
      return !this.jwtHelperService.isTokenExpired(token);
    }
    return false;
  }

  getToken() {
    return localStorage.getItem('user_access_token');
  }

  removeToken() {
    localStorage.removeItem('user_access_token');
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status === 400) {
        return throwError({
          status: error.status,
          msg: 'Bad request'
        });
      }

      if (error.status === 401) {
        return throwError({
          status: error.status,
          msg: 'Not authorized'
        });
      }

    }
    // return an observable with a user-facing error message
    return throwError('Something got wrong')
  };
}
