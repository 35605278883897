import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import * as Chart from 'chart.js'
import { Weight, WeightParsedForChart } from 'src/app/interfaces/weights.interface';
import { addDayToDate, average, toFixedIfNecessary, subtractDayFromDate } from 'src/app/shared/utils';
import { WeightsFilterDate } from 'src/app/shared/enums/weights.enum';

@Component({
  selector: 'app-weights-chart',
  templateUrl: './weights-chart.component.html',
  styleUrls: ['./weights-chart.component.scss']
})
export class WeightsChartComponent implements OnInit, AfterViewInit {

  @Input() weights;
  ctx;
  chart;
  canvas;

  dateFilters = [
    {value: WeightsFilterDate.LAST_WEEK, viewValue: 'Last 7 days'},
    {value: WeightsFilterDate.LAST_MONTH, viewValue: 'Last 30 days'},
    {value: WeightsFilterDate.LAST_3_MONTHS, viewValue: 'Last 90 days'},
    {value: WeightsFilterDate.ALL, viewValue: 'All'}
  ];
  dateFilterSelected = 1

  chartDatasetOptions = {
    fill: false,
    borderWidth: 2,
    lineTension: 0.2,
    pointHitRadius: 4,
    pointRadius: 2,
    pointHoverRadius: 2,
    pointHoverBorderWidth: 2
  }

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.weights.length) {
      this.initChart()
      this.getWeeklyMeanData(this.weights)
    }

  }

  parseData(weights: Weight[]) {
    return weights.map(weight => {
      return {
        x: new Date(weight.date),
        y: toFixedIfNecessary(weight.value, 2 )
      }
    })
  }

  filterData(weights: Weight[], dateFilterSelectionValue: number) {
    let startDate: Date;
    const endDate: Date = new Date()
    switch(dateFilterSelectionValue) {
      case WeightsFilterDate.LAST_WEEK:
        console.log('xd')
        startDate = subtractDayFromDate(endDate, 8)
        console.log(startDate)
        break;
      case WeightsFilterDate.LAST_MONTH:
        startDate = subtractDayFromDate(endDate, 31)
        break;
      case WeightsFilterDate.LAST_3_MONTHS:
        startDate = subtractDayFromDate(endDate, 91)
        break;
      default:
        console.log('default')
        return weights;
    }
    const weightsFiltered = weights.filter(weight => {
      return new Date(weight.date) >= startDate;
    })
    return weightsFiltered;
  }

  getWeeklyMeanData(weights: Weight[]) {
    const window = 7;
    let meanWeights = weights.map(weight => weight.value)
    let meanWeightsData = []
    for (let i = 0; i < meanWeights.length; i += 1) {
      meanWeightsData.push({
        x: weights[i].date,
        y: toFixedIfNecessary(average(meanWeights.slice(Math.max(0, i - 3), Math.min(meanWeights.length, i + 3))), 2)
      })
    }
    return meanWeightsData
  }


  getMinDate(weights: Weight[]) {
    return new Date(addDayToDate(new Date(weights[0].date), -1))
  }

  getMaxDate(weights: Weight[]) {
    return new Date(addDayToDate(new Date(weights[weights.length - 1].date), 1))
  }

  onDateFilterSelectionChange(event) {
    const d = this.filterData(this.weights, event.value)
    this.chart.data.datasets[0].data = this.parseData(d);
    this.chart.data.datasets[1].data = this.getWeeklyMeanData(d);
    this.chart.options.scales.xAxes[0].ticks.min = this.getMinDate(d);
    this.chart.options.scales.xAxes[0].ticks.max = this.getMaxDate(d);
    this.chart.update();
  }

  getChartDatasets() {
    const daily = {
      data: this.parseData(this.weights),
      borderColor: 'blue',
      pointBackgroundColor: 'blue',
      label: 'Daily',
      backgroundColor: 'blue',
      ...this.chartDatasetOptions
    }
    if (this.weights.length < 7) {
      return [daily]
    }
    const average = {
      data: this.getWeeklyMeanData(this.weights),
      backgroundColor: 'green',
      borderColor: 'green',
      pointBackgroundColor: 'green',
      label: 'Average',
      ...this.chartDatasetOptions
    }
    return [daily, average]
  }

  initChart() {
      Chart.plugins.register({
        beforeDatasetsDraw: function(chart) {
          if (chart.tooltip._active && chart.tooltip._active.length) {
              var activePoint = chart.tooltip._active[0],
                ctx = chart.ctx,
                y_axis = chart.scales['y-axis-0'],
                x = activePoint.tooltipPosition().x,
                topY = y_axis.top,
                bottomY = y_axis.bottom;
              // draw line
              ctx.save();
              ctx.beginPath();
              ctx.moveTo(x, topY);
              ctx.lineTo(x, bottomY);
              ctx.lineWidth = 1;
              ctx.strokeStyle = 'rgba(232, 232, 232, 1)';
              ctx.stroke();
              ctx.restore();
          }
        }
    });

    this.canvas = document.getElementById('canvas');
    this.ctx = this.canvas.getContext('2d');
    this.chart = new Chart(this.ctx, {
      type: 'line',
      data: {
        datasets: this.getChartDatasets()
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
        },
        tooltips: {
          enabled: true,
          mode: 'x',
          intersect: false
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'day'
            },
            ticks: {
              padding: 20,
              min: this.getMinDate(this.weights),
              max: this.getMaxDate(this.weights)
            },
            gridLines: {
              display: false
            }
          }]
        }
      }
    });
  }

}
