import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { SnackBarTypes } from './enums/snackbar.enums';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  openSnackBar(message: string, type: SnackBarTypes) {
    switch(type) {
      case SnackBarTypes.ERROR:
        this.snackBar.open(message, '', {
          duration: 4000,
          panelClass: 'snackbar-error'
        });
        break;
      case SnackBarTypes.SUCCESS: 
        this.snackBar.open(message, '', {
          duration: 4000,
          panelClass: 'snackbar-success'
        });
        break;
    }
  }
}
