import { Component, OnInit } from '@angular/core';
import { CookieService } from 'angular2-cookie/services/cookies.service';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-success-oauth',
  templateUrl: './login-success-oauth.component.html',
  styleUrls: ['./login-success-oauth.component.scss']
})
export class LoginSuccessOauthComponent implements OnInit {

  constructor(private cookieService: CookieService, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.authService.OAuthUserLogin(this.cookieService.get("token"))
    this.cookieService.remove("token")
    this.router.navigate(['/'])
  }

}
