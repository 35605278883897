import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { take } from 'rxjs/operators';
import { SnackBarTypes } from 'src/app/shared/enums/snackbar.enums';
import { SnackBarService } from 'src/app/shared/snackbar.service'
import { KEY_CODE } from 'src/app/shared/enums/key-codes.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../common/auth-styles.scss']
})
export class LoginComponent implements OnInit {
  
  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[\\w@]{2,}\\s*$')]],
    password: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private snackBarService: SnackBarService
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    if (this.loginForm.valid) {
      const {email, password } = this.loginForm.value;
      this.authService.login(email.trim(), password).pipe(take(1)).subscribe(res => {
        if(res.accessToken) {
          // this.authService.saveAuthCredentials(res.accessToken);
          this.router.navigate(['/'])
        }
      },
      error => {
        if (error.status === 401) {
          this.snackBarService.openSnackBar('Invalid login credentials', SnackBarTypes.ERROR)
        }
      })
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ENTER) {
      this.onSubmit();
    }
  }

  navigateToRegister() {
    this.router.navigate(['/register']);
  }

  navigateToPasswordReset() {
    this.router.navigate(['/password-reset']);
  }

  getErrorMessage(control: string) {
    if (this.loginForm.controls[control].hasError('required')) {
      return `Enter ${control}`;
    }
    if (control === 'email') {
      return this.loginForm.controls.email.hasError('pattern') ? 'Please enter valid email' : '';
    }
    return '';
  }


}
