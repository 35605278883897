import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { reduce } from 'rxjs/operators';

export function getApiUrl(endpoint) {
  return `${environment.apiUrl}${endpoint}`
}

export function setDateToMidnight(date: Date) {
  const start = moment(date, moment.ISO_8601).set({hour:0,minute:0,second:0,millisecond:0})
  return start.format()
}

export function addDayToDate(date: Date, day) {
  date.setDate(date.getDate() + day);
  return date
}

export function subtractDayFromDate(date: Date, day) {
  date.setDate(date.getDate() - day);
  return date
}

export function subtractDaysFromDateString(date: string, days) {
  const d = moment(date, moment.ISO_8601).subtract(days, 'days')
  return d.format()
}

export function toFixedIfNecessary( value, dp ){
  return +parseFloat(value).toFixed( dp );
}

export function average(array) {
  return array.reduce((a, b) => { return a + b }, 0) / array.length;
}