import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getApiUrl } from '../shared/utils';
import { Weight } from '../interfaces/weights.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WeightsService {

  constructor(private http: HttpClient) { }

  getWeights() {
    return this.http.get(getApiUrl('/weights'));
    // .pipe(
    //   map((res: Weight[]) => {
    //     return {
    //       values: res.flatMap(({ value }) => [value]),
    //       dates: res.flatMap(({ date }) => [date])
    //     }
    //   })
    // );
  }

  createWeight(weightPayload: Weight) {
    return this.http.put(getApiUrl('/weights'), weightPayload);
  }


}
