import { Component, OnInit } from '@angular/core';
import { WeightsService } from 'src/app/services/weights.service';
import { Weight } from 'src/app/interfaces/weights.interface';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  weights$;

  constructor(private weightsService: WeightsService) { }

  ngOnInit() {
    this.weights$ = this.weightsService.getWeights();
    // const w = this.weights$ = this.weightsService.getWeights().subscribe(res => { console.log(res)})
  }

  onAddWeight(event: Weight) {
    this.weightsService.createWeight(event).subscribe(res => {
      this.weights$ = this.weightsService.getWeights();
    })
  }

}
