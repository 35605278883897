import { Component, OnInit, Input } from '@angular/core';
import { subtractDaysFromDateString, toFixedIfNecessary } from 'src/app/shared/utils';

@Component({
  selector: 'app-weights-info',
  templateUrl: './weights-info.component.html',
  styleUrls: ['./weights-info.component.scss']
})
export class WeightsInfoComponent implements OnInit {
  @Input() weights;

  constructor() { }

  ngOnInit() {

  }

  getMaxWeight() {
    const max = Math.max(...this.weights.map(x => x.value));
    return max === -Infinity ? '-' : toFixedIfNecessary(max, 2 );
  }

  getMinWeight() {
    const min = Math.min(...this.weights.map(x => x.value));
    return min === Infinity ? '-' : toFixedIfNecessary(min, 2 );
  }

  getWeeklyChange() {
    if (this.weights.length === 0) {
      return '-';
    }
    const last = this.weights[this.weights.length - 1].date
    const weekBefore = subtractDaysFromDateString(last, 7);
    const lastWeek = this.weights.filter(x => x.date > weekBefore);
    const diff = lastWeek[lastWeek.length - 1].value - lastWeek[0].value;

    if (diff < 0) {
      return `${toFixedIfNecessary(diff, 2 )}`;
    } else {
      return `+ ${toFixedIfNecessary(diff, 2 )}`;
    }
    return '-'
  }

}
